.head {
    font-weight: bold;
    border-bottom: 1px solid gray;
    margin: 0.1em;
  }
  
  .text {
    font-size: 16px;
    margin: 0.1em;
  }
  
  .wrapper {
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 35em;
    min-width: 20em;
    font-size: 0.8em;
  }
  
  .item {
    border: 1px grey solid;
    margin: 0.3em;
    padding: 0.5em;
    width: 8em;
    text-align: center;
    box-shadow: 1px 3px 5px #80808082;
  }
  
  .edited {
    background: #fff1f0 ;
  }
  .cardTitle{
    margin-top: 10px;
  }
  .container{
    display: flex;
    justify-content: space-between;
  }
  .red{
    background-color: rgba(255, 0, 0, 0.2);
  }
  .green{
    background-color: rgba(2, 255, 2, 0.2);
  }
  .filterDropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  
  .btn {
    margin-right: 0.4em
  }
  
  .filter {
    display: flex;
    justify-content: space-between;
  }
  
@primary-color: #48c75b;