.filterDropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }
  
  .btn {
    margin-right: 0.4em
  }
  
  .filter {
    display: flex;
    justify-content: space-between;
  }
@primary-color: #48c75b;