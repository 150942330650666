.iconBtn {
    margin:5px;
}
.icon{
    font-size: 18px;
}
.descriptionLeft{
    width: 50%;
    padding-left: 1em;
    border-right: 1px solid rgba(128, 128, 128, 0.14);
}
.descriptionRight{
    width: 50%;
    padding-left: 1em;
}
.user{
    font-weight: bold;
}
.avatar{
    background: green;
    margin: 0.5em;
    margin-left: 0;
}
.wrapper{
    display: flex;
}
.divider{
    margin:0;
    height: 10px;
    background: transparent;
}
.resolve{
    align-items: center;
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
}
.commentWrapper{
    display: flex;
    background: white;
    // padding-top: 1em;
}
.author{
    border-right: 1px solid #e8e8e8;
    padding: 1em;
    width: 16%;
}
.antDescriptions{
    padding: 0;
    td{
        padding:0 !important;
    }
}
.info{
    font-weight: bold;
    padding-bottom: 1.5em;
}
.label{
    color: rgba(0, 0, 0, 0.78);
}
.comment{
    padding: 1em;
    padding-top: 2em;
    width: 84%;
}
.antComment{
    div{
        margin:0
    }
}
.title{
    margin-top: 2em;
    background: rgba(255, 255, 255, 0.63);
    padding: 1em;
}
.commentBtn{
    margin-right: 1em;
    margin-top: 1em;
}
@primary-color: #48c75b;