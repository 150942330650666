/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.sider.fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.sider.fixSiderbar :global(.ant-menu-root) {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.sider.light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.sider.light .logo {
  background: white;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.sider.light .logo h1 {
  color: #48c75b;
}
.sider.light :global(.ant-menu-light) {
  border-right-color: transparent;
}
.icon {
  width: 14px;
  margin-right: 10px;
}
:global .top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
:global .drawer .drawer-content {
  background: #001529;
}
:global .ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
:global .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
:global .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
:global .ant-menu-item .sider-menu-item-img + span,
:global .ant-menu-submenu-title .sider-menu-item-img + span {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}
