.iconBtn {
    margin:5px;
}
.icon{
    font-size: 18px;
}
.desColumn{
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@primary-color: #48c75b;