.container{
    margin-top: 2em;
}
.mainTable {
    width: 100%;
    border: 1px solid @primary-color;
    background-color: lighten(@primary-color , 45);
    td:nth-child(1) {
      text-transform: uppercase;
      font-weight: bold;
    }
  
    th {
      background-color: lighten(@primary-color , 15);
      color: white;
      border: 1px solid @primary-color;
    }
  
    td , th {
      padding: 8px 15px;
      border: 1px solid lighten(@primary-color , 30);
    }
  }
@primary-color: #48c75b;