.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}
.ant-tabs-bar{
    margin-bottom: 0;
}
