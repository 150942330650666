.container{
    margin-top: 2em;
}
.mainTable {
    width: 100%;
    border: 1px solid @primary-color;
    background-color: lighten(@primary-color , 45);
    td:nth-child(1) {
      text-transform: uppercase;
      font-weight: bold;
    }
  
    th {
      background-color: lighten(@primary-color , 15);
      color: white;
      border: 1px solid @primary-color;
    }
  
    td , th {
      padding: 8px 15px;
      border: 1px solid lighten(@primary-color , 30);
    }
  }
.logoCard{
  width: 120px;
  padding: 10px;
  margin: 0.5em 1em 0 1em;
}
.wrapper{
  display: flex;
  justify-content: center;
}
.list{
  margin: 1em;
}
.sortableList{
  display: flex;
  justify-content: center;
  width: 300px;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  div{
    width: 100%;
    div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      border-bottom: 1px solid #e8e8e8;
      width: 100%;
    }
  }
}
.handle{
  font-size: 1.2em;
  cursor: move;
  color: rgba(0, 0, 0, 0.45);
}
.icon{
  font-size: 1.2em;
  margin-right: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}
@primary-color: #48c75b;