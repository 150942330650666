.iconBtn {
    margin:5px;
}
.icon{
    font-size: 18px;
}
.description{
    display: flex;
    flex-flow: column;
    width: 400px;
    border: 1px solid rgba(128, 128, 128, 0.767);
    padding: 2em 2em 1em 2em;
}
.label{
    flex: 1;
    text-align: left;
}
.descriptionItem{
    flex: 1;
    display: flex;
    margin-bottom: 1em;
    color: rgba(0, 0, 0, 0.85);
    div{
        flex: 1;
        text-align: center;
    }
}
.wrapper{
    background: white;
    padding: 2em;
}
.onlineWrapper{
    margin-top: 30px;
    background: white;
}

.onlineMcps{
    width: 100%;
    padding: 2em 2em 1em 2em;
}
.tableHeading {
    margin-bottom: 20px;
    font-size: 17px;
    color:black;
    font-weight: 600;
}
@primary-color: #48c75b;