.antd-pro-containers-dashboard-style-iconBtn {
  margin: 5px;
}
.antd-pro-containers-dashboard-style-icon {
  font-size: 18px;
}
.antd-pro-containers-dashboard-style-description {
  display: flex;
  flex-flow: column;
  width: 400px;
  border: 1px solid rgba(128, 128, 128, 0.767);
  padding: 2em 2em 1em 2em;
}
.antd-pro-containers-dashboard-style-label {
  flex: 1 1;
  text-align: left;
}
.antd-pro-containers-dashboard-style-descriptionItem {
  flex: 1 1;
  display: flex;
  margin-bottom: 1em;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-containers-dashboard-style-descriptionItem div {
  flex: 1 1;
  text-align: center;
}
.antd-pro-containers-dashboard-style-wrapper {
  background: white;
  padding: 2em;
}
.antd-pro-containers-dashboard-style-onlineWrapper {
  margin-top: 30px;
  background: white;
}
.antd-pro-containers-dashboard-style-onlineMcps {
  width: 100%;
  padding: 2em 2em 1em 2em;
}
.antd-pro-containers-dashboard-style-tableHeading {
  margin-bottom: 20px;
  font-size: 17px;
  color: black;
  font-weight: 600;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-index-pageHeader {
  background: #fff;
  padding: 16px 32px 0 32px;
  border-bottom: 1px solid #e8e8e8;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-wide {
  max-width: 1200px;
  margin: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
  display: flex;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
  display: flex;
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-breadcrumb {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs {
  margin: 0 0 0 -8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs .ant-tabs-bar {
  border-bottom: none;
  margin-bottom: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo {
  flex: 0 1 auto;
  margin-right: 16px;
  padding-top: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo > img {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: block;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-left: 56px;
  min-width: 266px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group:not(:last-child),
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content {
  flex: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  flex: 0 1 auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  text-align: right;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-left: 88px;
  min-width: 242px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
    display: block;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

.antd-pro-components-page-header-wrapper-grid-content-main {
  width: 100%;
  height: 100%;
  min-height: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.antd-pro-components-page-header-wrapper-grid-content-main.antd-pro-components-page-header-wrapper-grid-content-wide {
  max-width: 1200px;
  margin: 0 auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-wrapper-index-content {
  margin: 24px 24px 0;
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-wrapper-index-content {
    margin: 24px 0 0;
  }
}

.antd-pro-components-form-item-styles-FormItem {
  margin-top: 32;
  width: 100;
}
.antd-pro-components-form-item-styles-layout {
  width: 200px !important;
}

.antd-pro-containers-company-settings-styles-container {
  margin-top: 2em;
}
.antd-pro-containers-company-settings-styles-mainTable {
  width: 100%;
  border: 1px solid #48c75b;
  background-color: #f8fdf8;
}
.antd-pro-containers-company-settings-styles-mainTable td:nth-child(1) {
  text-transform: uppercase;
  font-weight: bold;
}
.antd-pro-containers-company-settings-styles-mainTable th {
  background-color: #83d98f;
  color: white;
  border: 1px solid #48c75b;
}
.antd-pro-containers-company-settings-styles-mainTable td,
.antd-pro-containers-company-settings-styles-mainTable th {
  padding: 8px 15px;
  border: 1px solid #bdebc4;
}
.antd-pro-containers-company-settings-styles-logoCard {
  width: 120px;
  padding: 10px;
  margin: 0.5em 1em 0 1em;
}
.antd-pro-containers-company-settings-styles-wrapper {
  display: flex;
  justify-content: center;
}
.antd-pro-containers-company-settings-styles-list {
  margin: 1em;
}
.antd-pro-containers-company-settings-styles-sortableList {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.antd-pro-containers-company-settings-styles-sortableList div {
  width: 100%;
}
.antd-pro-containers-company-settings-styles-sortableList div div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
}
.antd-pro-containers-company-settings-styles-handle {
  font-size: 1.2em;
  cursor: move;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-containers-company-settings-styles-icon {
  font-size: 1.2em;
  margin-right: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}

.antd-pro-containers-company-milk-scanner-options-styles-container {
  margin-top: 2em;
}
.antd-pro-containers-company-milk-scanner-options-styles-mainTable {
  width: 100%;
  border: 1px solid #48c75b;
  background-color: #f8fdf8;
}
.antd-pro-containers-company-milk-scanner-options-styles-mainTable td:nth-child(1) {
  text-transform: uppercase;
  font-weight: bold;
}
.antd-pro-containers-company-milk-scanner-options-styles-mainTable th {
  background-color: #83d98f;
  color: white;
  border: 1px solid #48c75b;
}
.antd-pro-containers-company-milk-scanner-options-styles-mainTable td,
.antd-pro-containers-company-milk-scanner-options-styles-mainTable th {
  padding: 8px 15px;
  border: 1px solid #bdebc4;
}

.antd-pro-containers-company-all-style-iconBtn {
  margin: 5px;
}
.antd-pro-containers-company-all-style-icon {
  font-size: 18px;
}

.antd-pro-containers-devices-all-style-iconBtn {
  margin: 5px;
}
.antd-pro-containers-devices-all-style-icon {
  font-size: 18px;
}
.antd-pro-containers-devices-all-style-blackbox {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: '#fff';
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}
.antd-pro-containers-devices-all-style-sometext {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 3px 8px;
  z-index: 1;
  background-color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.antd-pro-containers-devices-all-style-language {
  font-size: 20px;
  font-weight: bold;
  width: 50%;
  text-align: center;
  margin-top: 10px;
  margin-left: 120px;
}
.antd-pro-containers-devices-all-style-farmer {
  margin-top: 10px;
  margin-left: 120px;
  font-size: 20px;
  font-weight: bold;
  width: 50%;
}
.antd-pro-containers-devices-all-style-card {
  display: block;
  float: left;
  width: 50%;
}
.antd-pro-containers-devices-all-style-card2 {
  width: 50%;
  float: right;
  text-align: center;
}
.antd-pro-containers-devices-all-style-divider {
  font-size: 20px !important;
  font-weight: bold !important;
  padding-top: 25px !important;
}
.antd-pro-containers-devices-all-style-saveBtn {
  margin: 2em 0;
}
.antd-pro-containers-devices-all-style-table {
  padding: 1em;
  margin-top: 1em;
  background: white;
}
.antd-pro-containers-devices-all-style-table table {
  margin-top: 1em;
}
.antd-pro-containers-devices-all-style-rateChart {
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(128, 128, 128, 0.589);
}
.antd-pro-containers-devices-all-style-cardContainer {
  margin-top: 1em;
  background: white;
  padding: 1em;
}
.antd-pro-containers-devices-all-style-snfTable {
  background: white;
}
.antd-pro-containers-devices-all-style-rateChartDetails {
  background: white;
  padding: 2em;
  border: 1px solid #80808069;
  margin: 1em 0;
}
.antd-pro-containers-devices-all-style-detailsTitle {
  font-weight: bold;
  border-bottom: 1px solid rgba(128, 128, 128, 0.431);
  padding-bottom: 0.5em;
}
.antd-pro-containers-devices-all-style-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-containers-payment-styles-head {
  font-weight: bold;
  border-bottom: 1px solid gray;
  margin: 0.1em;
}
.antd-pro-containers-payment-styles-text {
  font-size: 16px;
  margin: 0.1em;
}
.antd-pro-containers-payment-styles-wrapper {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 35em;
  min-width: 20em;
  font-size: 0.8em;
}
.antd-pro-containers-payment-styles-item {
  border: 1px grey solid;
  margin: 0.3em;
  padding: 0.5em;
  width: 8em;
  text-align: center;
  box-shadow: 1px 3px 5px #80808082;
}
.antd-pro-containers-payment-styles-edited {
  background: #fff1f0;
}
.antd-pro-containers-payment-styles-cardTitle {
  margin-top: 10px;
}
.antd-pro-containers-payment-styles-container {
  display: flex;
  justify-content: space-between;
}
.antd-pro-containers-payment-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-payment-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-payment-styles-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-containers-transactions-styles-head {
  font-weight: bold;
  border-bottom: 1px solid gray;
  margin: 0.1em;
}
.antd-pro-containers-transactions-styles-text {
  font-size: 16px;
  margin: 0.1em;
}
.antd-pro-containers-transactions-styles-wrapper {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 35em;
  min-width: 20em;
  font-size: 0.8em;
}
.antd-pro-containers-transactions-styles-item {
  border: 1px grey solid;
  margin: 0.3em;
  padding: 0.5em;
  width: 8em;
  text-align: center;
  box-shadow: 1px 3px 5px #80808082;
}
.antd-pro-containers-transactions-styles-edited {
  background: #fff1f0;
}
.antd-pro-containers-transactions-styles-cardTitle {
  margin-top: 10px;
}
.antd-pro-containers-transactions-styles-container {
  display: flex;
  justify-content: space-between;
}
.antd-pro-containers-transactions-styles-red {
  background-color: rgba(255, 0, 0, 0.2);
}
.antd-pro-containers-transactions-styles-green {
  background-color: rgba(2, 255, 2, 0.2);
}
.antd-pro-containers-transactions-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-transactions-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-transactions-styles-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-containers-farmer-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-farmer-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-farmer-all-styles-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-components-settings-styles-container {
  margin-top: 2em;
}
.antd-pro-components-settings-styles-container h4 {
  text-align: center;
}
.antd-pro-components-settings-styles-mainTable {
  width: 100%;
  border: 1px solid #48c75b;
  background-color: #f8fdf8;
}
.antd-pro-components-settings-styles-mainTable td:nth-child(1) {
  text-transform: uppercase;
  font-weight: bold;
}
.antd-pro-components-settings-styles-mainTable th {
  background-color: #83d98f;
  color: white;
  border: 1px solid #48c75b;
}
.antd-pro-components-settings-styles-mainTable td,
.antd-pro-components-settings-styles-mainTable th {
  padding: 8px 15px;
  border: 1px solid #bdebc4;
}
.antd-pro-components-settings-styles-wrapper {
  display: flex;
  justify-content: center;
}
.antd-pro-components-settings-styles-list {
  margin: 1em;
}
.antd-pro-components-settings-styles-sortableList {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.antd-pro-components-settings-styles-sortableList div {
  width: 100%;
}
.antd-pro-components-settings-styles-sortableList div div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
}
.antd-pro-components-settings-styles-handle {
  font-size: 1.2em;
  cursor: move;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-settings-styles-icon {
  font-size: 1.2em;
  margin-right: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.45);
}

.antd-pro-containers-mcp-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-mcp-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-mcp-all-styles-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-containers-mcp-mcp-group-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-mcp-mcp-group-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-mcp-mcp-group-all-styles-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-containers-help-desk-list-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-help-desk-list-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-help-desk-list-styles-filter {
  display: flex;
  justify-content: space-between;
}

.antd-pro-containers-user-all-style-iconBtn {
  margin: 5px;
}
.antd-pro-containers-user-all-style-icon {
  font-size: 18px;
}

.antd-pro-elements-trial-companies-views-styles-iconBtn {
  margin: 5px;
}
.antd-pro-elements-trial-companies-views-styles-icon {
  font-size: 18px;
}

.antd-pro-elements-suspended-companies-views-styles-iconBtn {
  margin: 5px;
}
.antd-pro-elements-suspended-companies-views-styles-icon {
  font-size: 18px;
}

.antd-pro-containers-collections-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-collections-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-collections-styles-filter {
  display: flex;
}
.antd-pro-containers-collections-styles-exportImg {
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 0px;
  cursor: pointer;
  margin-right: 5px;
}

.antd-pro-containers-tickets-all-style-iconBtn {
  margin: 5px;
}
.antd-pro-containers-tickets-all-style-icon {
  font-size: 18px;
}
.antd-pro-containers-tickets-all-style-desColumn {
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.antd-pro-elements-debug-views-styles-rootDiv {
  display: flex;
}
.antd-pro-elements-debug-views-styles-rightSideDivs {
  flex: 2 1;
  max-width: 30%;
  margin: 0 5px;
}
.antd-pro-elements-debug-views-styles-leftSideDivs {
  flex: 1 1;
  max-width: 20%;
  margin: 0 5px;
}
.antd-pro-elements-debug-views-styles-debugLogs {
  flex: 3 1;
}
.antd-pro-elements-debug-views-styles-lazyLog {
  width: 100%;
  height: calc(100vh - 120px);
}
.antd-pro-elements-debug-views-styles-lineClass a {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: unset;
}

.antd-pro-containers-tickets-details-style-iconBtn {
  margin: 5px;
}
.antd-pro-containers-tickets-details-style-icon {
  font-size: 18px;
}
.antd-pro-containers-tickets-details-style-descriptionLeft {
  width: 50%;
  padding-left: 1em;
  border-right: 1px solid rgba(128, 128, 128, 0.14);
}
.antd-pro-containers-tickets-details-style-descriptionRight {
  width: 50%;
  padding-left: 1em;
}
.antd-pro-containers-tickets-details-style-user {
  font-weight: bold;
}
.antd-pro-containers-tickets-details-style-avatar {
  background: green;
  margin: 0.5em;
  margin-left: 0;
}
.antd-pro-containers-tickets-details-style-wrapper {
  display: flex;
}
.antd-pro-containers-tickets-details-style-divider {
  margin: 0;
  height: 10px;
  background: transparent;
}
.antd-pro-containers-tickets-details-style-resolve {
  align-items: center;
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
}
.antd-pro-containers-tickets-details-style-commentWrapper {
  display: flex;
  background: white;
}
.antd-pro-containers-tickets-details-style-author {
  border-right: 1px solid #e8e8e8;
  padding: 1em;
  width: 16%;
}
.antd-pro-containers-tickets-details-style-antDescriptions {
  padding: 0;
}
.antd-pro-containers-tickets-details-style-antDescriptions td {
  padding: 0 !important;
}
.antd-pro-containers-tickets-details-style-info {
  font-weight: bold;
  padding-bottom: 1.5em;
}
.antd-pro-containers-tickets-details-style-label {
  color: rgba(0, 0, 0, 0.78);
}
.antd-pro-containers-tickets-details-style-comment {
  padding: 1em;
  padding-top: 2em;
  width: 84%;
}
.antd-pro-containers-tickets-details-style-antComment div {
  margin: 0;
}
.antd-pro-containers-tickets-details-style-title {
  margin-top: 2em;
  background: rgba(255, 255, 255, 0.63);
  padding: 1em;
}
.antd-pro-containers-tickets-details-style-commentBtn {
  margin-right: 1em;
  margin-top: 1em;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-exception-index-exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:before,
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  content: ' ';
  display: table;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content {
  flex: auto;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-actions button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-exception-index-exception {
    display: block;
    text-align: center;
  }
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-auth-layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}
.antd-pro-layouts-auth-layout-lang {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-lang .ant-dropdown-trigger {
  margin-right: 24px;
}
.antd-pro-layouts-auth-layout-content {
  padding: 32px 0;
  flex: 1 1;
}
@media (min-width: 768px) {
  .antd-pro-layouts-auth-layout-container {
    background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
  .antd-pro-layouts-auth-layout-content {
    padding: 32px 0 24px 0;
  }
}
.antd-pro-layouts-auth-layout-top {
  text-align: center;
}
.antd-pro-layouts-auth-layout-header {
  height: 44px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-header a {
  text-decoration: none;
}
.antd-pro-layouts-auth-layout-logo {
  height: 44px;
  vertical-align: top;
  margin-right: 16px;
}
.antd-pro-layouts-auth-layout-title {
  font-size: 33px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.antd-pro-layouts-auth-layout-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-footer-index-globalFooter {
  padding: 0 16px;
  margin: 48px 0 24px 0;
  text-align: center;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links {
  margin-bottom: 8px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a {
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:not(:last-child) {
  margin-right: 40px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-header-index-header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-global-header-index-logo {
  height: 64px;
  line-height: 64px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-components-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-components-global-header-index-menu .anticon {
  margin-right: 8px;
}
.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.antd-pro-components-global-header-index-trigger {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  -webkit-transition: all 0.3s, padding 0s;
  transition: all 0.3s, padding 0s;
  padding: calc((64px - 20px) / 2) 24px;
}
.antd-pro-components-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #48c75b;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
}
.antd-pro-components-global-header-index-dark {
  height: 64px;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover,
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action.opened {
  background: #48c75b;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-name {
    display: none;
  }
  .antd-pro-components-global-header-index-header i.antd-pro-components-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
    margin-right: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
@media screen and (max-width: 480px) {
  .antd-pro-components-header-dropdown-index-container {
    width: 100% !important;
  }
  .antd-pro-components-header-dropdown-index-container > * {
    border-radius: 0 !important;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-notice-list-list {
  max-height: 400px;
  overflow: auto;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-meta {
  width: 100%;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-avatar {
  background: #fff;
  margin-top: 4px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-iconElement {
  font-size: 32px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item.antd-pro-components-notice-icon-notice-list-read {
  opacity: 0.4;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:last-child {
  border-bottom: 0;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:hover {
  background: #f0fff0;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-title {
  font-weight: normal;
  margin-bottom: 8px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-description {
  font-size: 12px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.antd-pro-components-notice-icon-notice-list-notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-notice-icon-notice-list-notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.antd-pro-components-notice-icon-notice-list-clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
}
.antd-pro-components-notice-icon-notice-list-clear:hover {
  color: rgba(0, 0, 0, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-index-popover {
  width: 336px;
}
.antd-pro-components-notice-icon-index-noticeButton {
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.antd-pro-components-notice-icon-index-icon {
  padding: 4px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
  margin-bottom: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-search-index-headerSearch .anticon-search {
  cursor: pointer;
  font-size: 16px;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input {
  -webkit-transition: width 0.3s, margin-left 0.3s;
  transition: width 0.3s, margin-left 0.3s;
  width: 0;
  background: transparent;
  border-radius: 0;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input .ant-select-selection {
  background: transparent;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input input {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:hover,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:focus {
  border-bottom: 1px solid #d9d9d9;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input.antd-pro-components-header-search-index-show {
  width: 210px;
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-sider-menu-index-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.antd-pro-components-sider-menu-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-sider-menu-index-logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.antd-pro-components-sider-menu-index-sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar .ant-menu-root {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo {
  background: white;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo h1 {
  color: #48c75b;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .ant-menu-light {
  border-right-color: transparent;
}
.antd-pro-components-sider-menu-index-icon {
  width: 14px;
  margin-right: 10px;
}
.top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
.drawer .drawer-content {
  background: #001529;
}
.ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-item .sider-menu-item-img + span,
.ant-menu-submenu-title .sider-menu-item-img + span {
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-top-nav-header-index-head {
  width: 100%;
  -webkit-transition: background 0.3s, width 0.2s;
  transition: background 0.3s, width 0.2s;
  height: 64px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 64px;
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head.antd-pro-components-top-nav-header-index-light {
  background-color: #fff;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main {
  display: flex;
  height: 64px;
  padding-left: 24px;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main.antd-pro-components-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-left {
  flex: 1 1;
  display: flex;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-right {
  width: 324px;
}
.antd-pro-components-top-nav-header-index-logo {
  width: 165px;
  height: 64px;
  position: relative;
  line-height: 64px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-components-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-components-top-nav-header-index-light h1 {
  color: #002140;
}
.antd-pro-components-top-nav-header-index-menu {
  border: none;
  height: 64px;
  line-height: 64px;
}

.antd-pro-layouts-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-basic-layout-content {
  margin: 24px;
  padding-top: 64px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-containers-login-login-main {
  max-width: 368px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .antd-pro-containers-login-login-main {
    width: 95%;
  }
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon:hover {
  color: #48c75b;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-register {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other .antd-pro-containers-login-login-register {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-forgot {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-button {
  width: 100%;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-elements-register-view-styles-main {
  max-width: 480px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .antd-pro-elements-register-view-styles-main {
    width: 95%;
  }
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-icon:hover {
  color: #48c75b;
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-register {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-other .antd-pro-elements-register-view-styles-register {
  float: right;
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-forgot {
  float: right;
}
.antd-pro-elements-register-view-styles-main .antd-pro-elements-register-view-styles-button {
  width: 100%;
}
.antd-pro-elements-register-view-styles-btns {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 1em;
}

.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}
.ant-tabs-bar{
    margin-bottom: 0;
}

