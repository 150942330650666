.rootDiv{
    display: flex;
}
.rightSideDivs{
    flex: 2;
    max-width: 30%;
    margin: 0 5px;
}
.leftSideDivs{
    flex: 1;
    max-width: 20%;
    margin: 0 5px;
}
.debugLogs{
    flex: 3;
}
.lazyLog{
    width: 100%;
    height: ~"calc(100vh - 120px)";
}
.lineClass{
    a{
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: unset;
    }
}
@primary-color: #48c75b;