.iconBtn {
    margin:5px;
}
.icon{
    font-size: 18px;
}
.blackbox{
    // position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    // background: #000;
    z-index: 0;
    background-color:'#fff'; 
    font-size:30px; 
    font-weight:bold; 
    text-align:center
  }
  
  .sometext{
    // position: absolute;
    left: 0; right: 0; bottom: 0;
    // background: #ff4444;
    padding: 3px 8px;
    // color: #fff;
    z-index: 1;
    background-color:#fff; 
    font-size:20px; 
    font-weight:bold; 
    text-align:center
  }
  .language{
    font-size:20px; 
    font-weight:bold;
    width: 50%;
    // display: block;
    // float: left;
    // border:1px solid #000;
    text-align: center;
    margin-top: 10px;
    margin-left: 120px;
  }
  .farmer{
    // border:1px solid #000;
    // text-align: center;
    margin-top: 10px;
    margin-left: 120px;

    font-size:20px; 
    font-weight:bold;
    width: 50%;
    // display: inline-block;

    // display: flex;
    // float: right;
  }
  .card{
    display: block;
    float: left;
    width: 50%;
  }
  .card2{
      width: 50%;
    //   display: block;
      float: right;
    text-align: center;


  }
  .divider{
    font-size:20px !important; 
    font-weight:bold !important;
    padding-top: 25px !important;
  }
  .saveBtn {
    margin: 2em 0;
  }
  .table{
    padding:1em;
    margin-top: 1em;
    background: white;
    table{
      margin-top: 1em;
    }
  }
  .rateChart{
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(128, 128, 128, 0.589);
  }
  .cardContainer{
    margin-top: 1em;
    background: white;
    padding: 1em;
  }
  .snfTable{
    background: white;
  }
  .rateChartDetails{
    background: white;
    padding: 2em;
    border: 1px solid #80808069;
    margin: 1em 0;
  }
  .detailsTitle{
    font-weight: bold;
    border-bottom: 1px solid rgba(128, 128, 128, 0.431);
    padding-bottom: 0.5em;
  }
  .filter{
    display: flex;
    justify-content: space-between;
    // margin-bottom: 1em;
  }
@primary-color: #48c75b;